import React from "react";

export default function SocialLink({ href, sn }: { href: string; sn: string }) {
  let iconClass = "";

  switch (sn) {
    case "facebook":
      iconClass = "fab fa-facebook";
      break;
    case "linkedin":
      iconClass = "fab fa-linkedin";
      break;
    case "whatsapp":
      iconClass = "fab fa-whatsapp";
      break;
    case "github":
      iconClass = "fab fa-github";
      break;
    case "mail":
      iconClass = "fas fa-envelope";
      break;
    default:
      iconClass = "";
  }
  return (
    <div className="column icon-column">
      <a href={href} className={`icon social-icon ${sn}`} target="_blank">
        <span className={iconClass} />
      </a>
    </div>
  );
}
