import React from "react";
import SocialLinks from "./SocialLinks";

export default function Footer() {
  return (
    <footer className="footer neg-box-padding has-text-centered">
      <div className="content">
        <h1 className="title is-size-4">Always developing.</h1>
        <SocialLinks extraClass="is-hidden-desktop" />
        <p>Built by me @ 2019</p>
        <a href="https://bulma.io">
          <img
            src="https://bulma.io/images/made-with-bulma--black.png"
            alt="Made with Bulma"
            width="128"
            height="24"
          />
        </a>
      </div>
    </footer>
  );
}
