import React from "react";

export default function LocationSubtitle({ text }: { text: string }) {
  return (
    <div className="level subtitle subtitle-negative-mt is-6 has-text-centered">
      <div className="level-item">
        <span className="icon">
          <i className="fas fa-map-marker-alt" />
        </span>
        <h3>{text}</h3>
      </div>
    </div>
  );
}
