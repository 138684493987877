import React from "react";
import SocialLink from "./SocialLink";

export default function SocialLinks({
  extraClass = ""
}: {
  extraClass?: string;
}) {
  return (
    <div className={`level social-links is-inline-block ${extraClass}`}>
      <div className="columns is-marginless">
        <SocialLink href="mailto:bit.asaf@gmail.com" sn="mail" />
        <SocialLink href="https://github.com/asafbiton" sn="github" />
        <SocialLink
          href="https://www.linkedin.com/in/asafbiton"
          sn="linkedin"
        />
      </div>
    </div>
  );
}
