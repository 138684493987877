import React from "react";
import PropTypes from "prop-types";

export default function SkillEntry({
  title,
  list = [],
  iconClass = ""
}: {
  title?: string;
  list?: string[];
  iconClass?: string;
}) {
  return (
    <div className="section-entry content">
      {iconClass ? (
        <span className="icon skill-icon">
          <i className={iconClass} />
        </span>
      ) : null}
      <h4 className="title is-size-5">{title}</h4>
      <div>
        <ul>
          {list.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
