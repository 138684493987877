import React from "react";

export default function Separator({ sepWidth = "75%", className = "" }) {
  return (
    <div
      className={`separator is-centered ${className}`}
      style={{ width: sepWidth }}
    />
  );
}
