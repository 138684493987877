import React from "react";
import { WorkExperience, Education } from "../interfaces/StateInterface";

function renderWorkExperience({
  entryDetails
}: {
  entryDetails: WorkExperience;
}) {
  return (
    <div className="section-entry">
      <div className="top level is-mobile">
        <div className="level-right is-shrinking">
          <div className="level-item">
            <img
              src={entryDetails.Image}
              className="image is-64x64 rnd-4px border-1px"
              alt="Company"
            />
          </div>
          <div className="level-item is-shrinking">
            <div>
              <div>{entryDetails.Company}</div>
              <div><b>{entryDetails.Role}</b></div>
            </div>
          </div>
        </div>
        <div className="level-left">{entryDetails.Duration}</div>
      </div>
      <div className="content">
        <p dangerouslySetInnerHTML={{ __html: entryDetails.Description }} />
      </div>
    </div>
  );
}

function renderEducationEntry({ entryDetails }: { entryDetails: Education }) {
  return (
    <div className="section-entry">
      <div className="top level is-mobile">
        <div className="level-right is-shrinking">
          <div className="level-item">
            <img
              src={entryDetails.Image}
              className="image is-64x64 rnd-4px border-1px"
              alt="Institute"
            />
          </div>
          <div className="level-item is-shrinking">
            <div>{entryDetails.Institute}</div>
          </div>
        </div>
        <div className="level-left">{entryDetails.Duration}</div>
      </div>
      <div className="content">
        <p dangerouslySetInnerHTML={{ __html: entryDetails.Description }} />
      </div>
    </div>
  );
}

function isWorkExperience({
  entryDetails
}: {
  entryDetails: WorkExperience | Education;
}) {
  return entryDetails.hasOwnProperty("Company");
}

export default function SectionEntry({ entryDetails }: { entryDetails: any }) {
  return isWorkExperience({ entryDetails })
    ? renderWorkExperience({ entryDetails })
    : renderEducationEntry({ entryDetails });
}
