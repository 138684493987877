import React from "react";
import ProfileImage from "./ProfileImage";
import LocationSubtitle from "./LocationSubtitle";
import Separator from "./Separator";
import Section from "./Section";
import SectionEntry from "./SectionEntry";
import PersonalSummary from "./PersonalSummary";
import SkillEntry from "./SkillEntry";
import Footer from "./Footer";
import { State, Project } from "../interfaces/StateInterface";
import SocialLinks from "./SocialLinks";

export default function Card({ info }: { info: State }) {
  return (
    <div className="container is-desktop container-mt container-mb">
      <div className="box main-box">
        <Section>
          <ProfileImage image={info.PersonalInfo.Image} />
          <SocialLinks extraClass="is-desktop is-hidden-mobile" />
          <h1 className="title is-3 has-text-centered">
            {info.PersonalInfo.Name}
          </h1>
          <PersonalSummary text={info.PersonalInfo.Role} />
          <LocationSubtitle text={info.PersonalInfo.Location} />
          <p className="about-me content has-text-centered">
            {info.PersonalInfo.Summary}
          </p>
        </Section>
        <Separator />
        <Section title="Experience">
          {info.WorkExperience.map((entry, i) => (
            <SectionEntry key={i} entryDetails={entry} />
          ))}
        </Section>
        <Separator />
        <Section title="Education">
          {info.Education.map((entry, i) => (
            <SectionEntry key={i} entryDetails={entry} />
          ))}
        </Section>
        <Separator />
        <Section title="Skills" className="skills">
          <div className="section-entry">
            <div className="columns has-text-centered">
              <div className="column is-one-third">
                <SkillEntry
                  title="Professional Skills"
                  list={info.Skills.ProfessionalSkills}
                  iconClass="fas fa-user-tie"
                />
              </div>
              <div className="column is-one-third">
                <SkillEntry
                  title="Programming Languages"
                  list={info.Skills.ProgrammingLanguages}
                  iconClass="fas fa-code"
                />
              </div>
              <div className="column is-one-third">
                <SkillEntry
                  title="Technologies"
                  list={info.Skills.Technologies}
                  iconClass="fab fa-react"
                />
              </div>
            </div>

            <div className="columns has-text-centered">
              <div className="column is-half">
                <SkillEntry
                  title="Personal Skills"
                  list={info.Skills.PersonalSkills}
                  iconClass="fas fa-handshake"
                />
              </div>
              <div className="column is-half">
                <SkillEntry
                  title="Languages"
                  list={info.Skills.Languages}
                  iconClass="fas fa-language"
                />
              </div>
            </div>
          </div>
        </Section>
        {/* <Separator />
        <Section title="Projects" className="projects">
          <div className="section-entry">{renderProjects(info.Projects)}</div>
        </Section> */}
        <Footer />
      </div>
    </div>
  );
}

function renderProjects(projects: Project[]) {
  const neededColumns: number = Math.floor(projects.length / 2) || 1;
  let columns = [];
  let currentProjects = [];

  for (let i = 0; i < neededColumns; i++) {
    const start = i * 2;
    const end = i * 2 + 2 > projects.length ? projects.length : i * 2 + 2;
    currentProjects = projects.slice(start, end);
    columns.push(
      <div key={i} className="columns">
        {currentProjects.map((entry, idx) => (
          <div key={idx} className="column is-one-third">
            <a
              href="https://github.com/asafbiton96/react-weather-app"
              className="project box is-clipped"
            >
              <figure className="is-clipped">
                <img src={entry.Image} />
              </figure>
              <div className="content">
                <p>{entry.Description}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    );
  }

  return columns;
}
