import React, { Component } from "react";
import "./App.scss";
import Card from "./components/Card";
import { State } from "./interfaces/StateInterface";

import ProfilePic from './static/img/profile.jpeg';
import Snyk from "./static/img/snyk.svg"
import Aman from "./static/img/AmanLogo.svg";
import Atidim from "./static/img/Amit.png";

class App extends Component<{}, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      PersonalInfo: {
        Image: ProfilePic,
        Name: "Asaf Biton",
        Role: "Security Team Lead @ Snyk",
        Location: "London, UK",
        Summary:
          "I am a Cyber Security Researcher with background in both open-source research as well as APT research. I have experience leading and building product-focused research teams. In my free time I like to keep active, read, play the piano and study new languages."
      },
      WorkExperience: [
        {
          Image: Snyk,
          Company: "Snyk",
          Role: "Security Team Lead: Snyk Code",
          Duration: "2020 - Present",
          Description: `<ul><li>Build a new team to support a new product</li>
          <li>Develop new work methodologies and fascilitate planning and design of rule creation and research</li>
          <li>Fascilitate and direct research of new vulnerability trends in open source</li>
          <li>Research and write SAST rules</li>
          </ul>`
        },
        {
          Image: Snyk,
          Company: "Snyk",
          Role: "Security Researcher",
          Duration: "2019 - 2020",
          Description: `<ul><li>Discovering, researching, and triaging of new vulnerabilities in the Open Source domain.</li>
          <li>Developing research tools and trainings.</li>
          <li>Writing automations for vulnerability triaging and catalouging.</li>
          <li>Relocated to the UK as part of my role.</li>
          </ul>`
        },
        {
          Image: Aman,
          Company: "8200 Israel Intelligence Corps",
          Role: "Cyber Security Expert",
          Duration: "2014 - 2019",
          Description: `<ul><li>Cyber security R&D team member. Expertise and experience with research of cyber security threats, mitigations, products in both managed and unmanaged networks.</li>
          <li>Professional understanding of network topologies, configurations, and protocols.</li>
          <li>Experience as a team (technological) leader for a period of 2.5 years, managing 2-3 team members, leading complex and ground-breaking R&D cyber security projects including the planning, development and implementation stages of operational processes and methodologies.</li>
          <li>Experience working in an operational environment - Intensive work hours, multitasking under pressure.</li></ul>`
        }
      ],
      Education: [
        {
          Image: Atidim,
          Institute: "Amit Atidim High School",
          Duration: "2009 - 2014",
          Description:
            `<ul>
            <li>Full "Bagrut" certificate</li>
            <li>Majored in Physics and Computer Science (with honors)</li>
            <li>Graduated top 3 in my class</li>
            </ul>`
        }
      ],
      Skills: {
        ProfessionalSkills: [
          "Cyber Security Research",
          "Windows, Linux Expertise",
          "Forensics",
          "Networking (TCP/IP) Protocols",
          "Network Security",
          "Open Source Security",
          "Team Leadership",
          "Project Management", 
          "Static Analysis (SAST)"
        ],
        ProgrammingLanguages: [
          "Python",
          "JavaScript",
          "C++",
          "C",
          "PHP",
          "SQL"
        ],
        Technologies: [
          "Node.js",
          "React",
          "VC (Git)",
          "Web (HTML / CSS / JS)",
          "Containers",
          "BigQuery"
        ],
        PersonalSkills: [
          "Strong work ethic",
          "Adaptable",
          "Flexible",
          "Multi-tasker",
          "Efficient",
          "Leader",
          "Team-player"
        ],
        Languages: [
          "Hebrew (native)",
          "English (fluent)",
          "Japanese (beginner)"
        ]
      },
      Projects: [
        {
          Image: require("./static/img/project-weather.png"),
          Title: "Weather App",
          Description:
            "Weather app written in React.js using React-Redux, Routers and more."
        }
      ]
    };
  }

  render() {
    return (
      <div className="App">
        <header className="header" />
        <Card info={this.state} />
      </div>
    );
  }
}

export default App;
