import React from "react";
import Separator from "./Separator";
import PropTypes from "prop-types";

export default function Section({
  title,
  children,
  className = ""
}: {
  title?: string;
  children?: JSX.Element[] | JSX.Element;
  className?: string;
}) {
  return (
    <div className={`section ${className}`}>
      {title ? (
        <>
          <h1 className="title is-4 has-text-centered is-spaced">{title}</h1>
        </>
      ) : null}
      <>{children}</>
    </div>
  );
}

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string
};
